import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import exportLimit from "../../common/export-limit.vue";
import reportService from "./reports-service";
import Utility from "../../../shared/utility";
import DisplayTextData from "@/components/common/display-text-data.vue";
export default {
  name: "partsDueReport",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      specificSelected: "",
      showData: false,
      exportDialog: false,
      hideSpecific: false,
      showTable: false,
      shippingDetailsArray: [],
      disableDate: false,
      showPart: false,
      showPickDetails: false,
      partNum: "",
      partDesc: "",
      mainPage: "",
      pickExcelName: "",
      searchPicking:"",
      searchConsolidation:"",
      searchAudit:"",
      searchShip:"",
      page: 1,
      displayCost: "",
      totalPages: 0,
      subPage: "",
      restForm: false,
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      businessList: [],
      selectedBusiness: "",
      totalRecordsPicking: 0,
      totalRecordsConsolidation: 0,
      totalRecordsShipping: 0,
      totalRecordsAudit: 0,
      consolidationList: [],
      exportConsolidationList:[],
      exportShipList: [],
      exportPickList: [],
      exportAuditList:[],
      shipList: [],
      pickList: [],
      auditList: [],
      lineItemList: [],
      showTableData: false,
      pickHeader: [
        { text: "Order No", value: "OrderNo", class: "primary customwhite--text",align:'start' },
        { text: "Require Date", value: "Require_Date", class: "primary customwhite--text",align:'start' },
        { text: "Type", value: "Type", class: "primary customwhite--text",align:'start' },
        { text: "Status", value: "Status", class: "primary customwhite--text",align:'start' },
        { text: "Action", value: "action", class: "primary customwhite--text",align:'start' },
      ],
      consolidationHeader: [
        { text: "Order No", value: "OrderNo", class: "primary customwhite--text",align:'start' },
        { text: "Type", value: "Type", class: "primary customwhite--text",align:'start' },
        { text: "Status", value: "Status", class: "primary customwhite--text",align:'start' },
      ],
      auditHeader: [
        { text: "Order No", value: "OrderNo", class: "primary customwhite--text",align:'start' },
        { text: "Type", value: "Type", class: "primary customwhite--text",align:'start' },
        { text: "Status", value: "Status", class: "primary customwhite--text",align:'start' },
      ],
      shippingHeader: [
        { text: "Order No", value: "OrderNo", class: "primary customwhite--text",align:'start' },
        { text: "Require Date", value: "Require_Date", class: "primary customwhite--text",align:'start' },
        { text: "Type", value: "Type", class: "primary customwhite--text",align:'start' },
        { text: "Status", value: "Status", class: "primary customwhite--text",align:'start' },
      ],
      lineItemHeader: [
        { text: "Line No", value: "Line#", class: "primary customwhite--text" },
        { text: "Type", value: "Type", class: "primary customwhite--text" },
        { text: "Part No", value: "Part#", class: "primary customwhite--text" },
        { text: "Warehouse", value: "Warehouse", class: "primary customwhite--text" },
        { text: "Location", value: "Location", class: "primary customwhite--text" },
        { text: "Order Qty", value: "Order Qty", class: "primary customwhite--text" },
        { text: "Picked", value: "Picked", class: "primary customwhite--text" },
        { text: "Shipped", value: "Shipped", class: "primary customwhite--text" },
        { text: "Assigned", value: "Assigned", class: "primary customwhite--text" },
      ],
      lpnHeader: [
        { text: "LPN", value: "LPN", class: "primary customwhite--text" },
        { text: "Status", value: "Status", class: "primary customwhite--text" },
        { text: "On LPN", value: "On LPN", class: "primary customwhite--text" },
        { text: "Location", value: "Location", class: "primary customwhite--text" },
        { text: "Owner", value: "Owner", class: "primary customwhite--text" },
      ],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  async mounted() {
    let businessObj = {
      pass: 1,
      user_id: this.userId,
    };
    let businessData = await reportService.getPickPackBusiness("post", businessObj);
    this.businessList = businessData;
  },
  computed: {},

  methods: {
    // File Name for Pick
    fileName(name) {
      return Utility.excelFileName(name);
    },

    // row Color
    getRowStyle(item) {
      return "background-color:" + item.linecolor +";padding:0, 10px !important"
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },

    // onChange business
    async onChangeBusiness(value) {
      if(value){
      this.showTableData = true;
      let dataObj = {
        pass: 2,
        user_id: this.userId,
        bu_id: this.selectedBusiness,
      };
      let data = await reportService.getPickPackBusiness("post", dataObj);
      this.pickList = data?.Resultset1;
      this.pickList?.forEach((element) => {
        if (element.Require_Date) element.Require_Date = Utility.convertESTToLocalOnlyDate(element.Require_Date);
      });
      this.exportConsolidationList=[],
      this.exportShipList= []
      this.exportAuditList=
      this.totalRecordsPicking = this.pickList?.length;
      this.consolidationList = data?.Resultset2;
      this.totalRecordsConsolidation = this.consolidationList?.length;
      this.auditList = data?.Resultset3;
      this.totalRecordsAudit = this.auditList?.length;
      this.shipList = data?.Resultset4;
      this.shipList?.forEach((element) => {
        if (element.Require_Date) element.Require_Date = Utility.convertESTToLocalOnlyDate(element.Require_Date);
      });
      this.exportPickList=this.pickList.map((x) => ({
        OrderNo: x.OrderNo,
        Require_Date: x.Require_Date,
        Type: x.Type,
        Status: x.Status,

      }));
      this.exportConsolidationList=this.consolidationList.map((x) => ({
        OrderNo: x.OrderNo,
        Require_Date: x.Require_Date,
        Type: x.Type,
        Status: x.Status,

      }));
      this.exportAuditList=this.auditList.map((x) => ({
        OrderNo: x.OrderNo,
        Require_Date: x.Require_Date,
        Type: x.Type,
        Status: x.Status,

      }));
      this.exportShipList=this.shipList.map((x) => ({
        OrderNo: x.OrderNo,
        Require_Date: x.Require_Date,
        Type: x.Type,
        Status: x.Status,

      }));
      this.totalRecordsShipping = this.shipList?.length;
    }},
    //shipping Details
    async pickDetails(item) {
      this.showPickDetails = true;
      let po_id = item.po_id;
      let so_id = item.so_id;
      let pickData = await reportService.getPickDetails("get", po_id, so_id);
      this.shippingDetailsArray = pickData.Resultset.map((x) => ({
        label: x.attribute,
        value: x.value,
      }));
      this.lineItemList = pickData.Resultset1;
      this.lpnList = pickData?.Resultset2;
    },
    closeDialog() {
      this.showPickDetails = false;
    },

    resetFunction(){
      this.showTableData=false;
      this.selectedBusiness="";
      this.totalRecordsPicking=0;
      this.totalRecordsConsolidation=0;
      this.totalRecordsShipping= 0;
      this.totalRecordsAudit=0;
      this.consolidationList=[];
      this.shipList= [];
      this.pickList= [];
      this.auditList= [];
      this.lineItemList= [];
      this.searchPicking="";
      this.searchConsolidation="";
      this.searchAudit="";
      this.searchShip="";
    }


  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
    DisplayTextData,
  },
};
