<template>
  <v-btn title="Back to top" v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="primary" @click="toTop">
    <v-icon>mdi mdi-chevron-up</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: 'backToTop',
  data() {
    return {
      fab: false,
    }
  },
  methods: {
    // Scroll Check method 
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    // To go to the top
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>